import React from 'react';

import bgCenter from './images/bg.png';
import './App.scss';
import { Badge, Button, Card, Col, Container, Dropdown, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faMediumM, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBook, faFutbol, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
// import Countdown from 'react-countdown';

const App = () => {
  return (
    <React.Fragment>
      <div
        className="w-100 vh-100 position-fixed bg-main"
        style={{
          backgroundImage: `url(${bgCenter})`,
        }}
      />
      <Container className="vh-100 d-flex flex-column">
        <Stack direction="horizontal" className="align-self-end mb-auto pt-3 gap-3">
          <Dropdown align="end">
            <Dropdown.Toggle variant="outline-secondary" id="lauch-app-dd" className="rounded-pill">
              <FontAwesomeIcon icon={faNetworkWired} />
              <span className="ms-1">Previous</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="https://bsc.banksydao.finance">BSC</Dropdown.Item>
              <Dropdown.Item href="https://ftm.banksydao.finance">Fantom</Dropdown.Item>
              <Dropdown.Item href="https://avax.banksydao.finance">Avalanche</Dropdown.Item>
              <Dropdown.Item href="https://doge.banksydao.finance">DogeChain</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Row className="justify-content-between mb-3 mb-lg-auto">
          <Col lg="auto">
            <Card bg="dark" className="bg-opacity-25 mb-5 rounded-4">
              <Card.Body className="text-center">
                <h1
                  className="text-secondary mb-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.location.href = 'https://bsc.banksydao.finance';
                  }}
                >
                  BANKSYDAO
                </h1>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="auto">
            <Card bg="dark" className="bg-opacity-25 mb-5 rounded-4">
              <Card.Body className="text-center">
                <h1
                  className="text-secondary mb-0"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.location.href = 'https://degoverse.bet';
                  }}
                >
                  DEGOVERSE
                </h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card bg="dark" className="bg-opacity-50 mb-3 rounded-4 align-self-lg-center">
          <Card.Body className="text-center">
            <h4 className="text-secondary">
              STEALTH PRESALE<br />
              NFT FARMING QATAR WORLD CUP EDITION
            </h4>
            <h4 className="text-warning mb-3">
              <Button size="lg" variant="outline-secondary" href="https://bsc.banksydao.finance">LAUNCH APP</Button>
            </h4>
            <Badge pill bg="info" className="fs-6 me-md-2 mb-1 mb-lg-none">
              <FontAwesomeIcon icon={faFutbol} />
              <span className="ms-1">QATAR 2022 EDITION</span>
            </Badge>
            <Badge pill bg="warning" className="text-dark fs-6">
              <FontAwesomeIcon icon={faFutbol} />
              <span className="ms-1">POWERED BY BINANCE</span>
            </Badge>
          </Card.Body>
        </Card>
        <Stack direction="horizontal" className="gap-3 mb-3 mx-auto">
          <Button variant="link" href="https://github.com/BanksyFarm/contracts-v2" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} size="lg" />
          </Button>
          <Button variant="link" href="https://docs.banksydao.finance/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faBook} size="lg" />
          </Button>
          <Button variant="link" href="https://banksyfarm.medium.com/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faMediumM} size="lg" />
          </Button>
          <Button variant="link" href="https://www.youtube.com/channel/UChfWl-DNpRhLs_2NszfEpoA" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="lg" />
          </Button>
          <Button variant="link" href="https://twitter.com/banksy_dao" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </Button>
          <Button variant="link" href="https://t.me/BanksyDAO" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTelegram} size="lg" />
          </Button>
        </Stack>
      </Container>
    </React.Fragment>
  );
}

export default App;
